import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5885e6a4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  action: "/"
};
const _hoisted_2 = {
  class: "tel-children"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = ["onClick"];
const _hoisted_5 = ["src"];
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  class: "footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_search = _resolveComponent("van-search");
  const _component_van_col = _resolveComponent("van-col");
  const _component_router_link = _resolveComponent("router-link");
  const _component_van_row = _resolveComponent("van-row");
  const _component_van_swipe_item = _resolveComponent("van-swipe-item");
  const _component_van_swipe = _resolveComponent("van-swipe");
  const _component_router_view = _resolveComponent("router-view");
  const _component_van_tab = _resolveComponent("van-tab");
  const _component_van_cell = _resolveComponent("van-cell");
  const _component_van_tabs = _resolveComponent("van-tabs");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_row, null, {
    default: _withCtx(() => [_createVNode(_component_van_col, {
      span: "16"
    }, {
      default: _withCtx(() => [_createElementVNode("form", _hoisted_1, [_createVNode(_component_van_search, {
        shape: "round",
        modelValue: $data.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.value = $event),
        onSearch: $options.onSearch,
        placeholder: _ctx.$t('message.search')
      }, null, 8, ["modelValue", "onSearch", "placeholder"])])]),
      _: 1
    }), _createVNode(_component_van_col, {
      span: "8",
      class: "tel"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_router_link, {
        to: "/dist/form"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('message.feedback')), 1), _createElementVNode("img", {
          src: require('@/assets/images/fk.jpg'),
          alt: ""
        }, null, 8, _hoisted_3)]),
        _: 1
      })])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_van_swipe, {
    autoplay: 5000,
    "lazy-render": ""
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('message.swipe'), (item, index) => {
      return _openBlock(), _createBlock(_component_van_swipe_item, {
        key: index
      }, {
        default: _withCtx(() => [_createElementVNode("a", {
          onClick: $event => $options.toContant(item.playback),
          target: "_blank"
        }, [_createElementVNode("img", {
          style: {
            "width": "100%"
          },
          src: item.image
        }, null, 8, _hoisted_5)], 8, _hoisted_4)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }), _createVNode(_component_van_tabs, {
    active: $data.active,
    "onUpdate:active": _cache[1] || (_cache[1] = $event => $data.active = $event),
    "title-active-color": _ctx.$t('message.color'),
    color: _ctx.$t('message.color'),
    onClick: $options.tabHandler
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tab, {
      title: _ctx.$t('message.tabTitle.left')
    }, {
      default: _withCtx(() => [(_openBlock(), _createBlock(_component_router_view, {
        key: $options.key,
        tabLeftTitle: $data.tabLeftTitle,
        tabLeftData: $data.tabLeftData
      }, null, 8, ["tabLeftTitle", "tabLeftData"]))]),
      _: 1
    }, 8, ["title"]), _createVNode(_component_van_tab, {
      title: _ctx.$t('message.tabTitle.right')
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tabRightData, (item, index) => {
        return _openBlock(), _createBlock(_component_van_cell, {
          key: index
        }, {
          default: _withCtx(() => [_createElementVNode("a", {
            onClick: $event => $options.toContant(item.playback),
            target: "_blank"
          }, _toDisplayString(item.text), 9, _hoisted_6)]),
          _: 2
        }, 1024);
      }), 128))]),
      _: 1
    }, 8, ["title"])]),
    _: 1
  }, 8, ["active", "title-active-color", "color", "onClick"]), _createVNode(_component_van_row, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('message.footer')), 1)])]),
    _: 1
  })], 64);
}