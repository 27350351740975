module.exports = {
    message: {
        app: "birds",
        language: "zh",
        color: "#4286FE",
        search: "有问题搜一下",
        feedback: "问题反馈",
        // 轮播图
        swipe: [
            {image: require("@/assets/images/birds/cn/swipe-android@2x.png"), playback: "http://110.41.150.61:8085/file/video/cloudbirds1/千鸟WIFI产品配网.mp4"},
            {image: require("@/assets/images/birds/cn/swipe-ios@2x.png"), playback: "http://110.41.150.61:8085/file/video/cloudbirds1/千鸟WIFI产品配网.mp4"},
            // {image: require("@/assets/images/birds/cn/swipe@2x.png"), playback: "#"}
        ],
        tabTitle:{
            left: "常见问题解答",
            right: "操作视频",
        },
        contentImage: require("@/assets/images/birds/cn/content-image.png"),
        // 常见问题解答
        tabLeft: [
            {
                title: "添加设备",
                active: require("@/assets/images/birds/cn/icon-add-hover.png"),
                normal: require("@/assets/images/birds/cn/icon-add.png"),
                data: [
                    {
                        text: "摄像机连接成功之后提示密码错误怎么处理？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.复位摄像机(复位步骤见“常见问题解答”中的“功能使用栏目”），删除APP设备栏里面的设备，重新配置摄像机连接的WiFi。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.多次复位摄像机，添加成功之后还是密码错误。把摄像机ID号提供给客服人员或者发送邮箱到fae@dayunlinks.com</span><span style=\"font-family: 宋体; font-size: 18px;\">，我们会及时联系处理。</span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "设备提示“连接完成，欢迎使用”之后，设备栏设备显示离线",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.重启路由器之后复位设备，删除设备栏离线设备，根据语音提示重新添加摄像机。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.换网络之后重新添加摄像机：换一个wifi重新添加摄像机，比如可以打开另外一台手机的手机热点，以此网络为摄像机所要连接的<span style=\"font-family: Calibri; font-size: 16px;\"><span style=\"font-family: 宋体; font-size: 18px;\">wifi。</span></span></span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">3.如果依然无法显示正常，请联系客服人员或发送至support@sz-cloudbirds.com邮箱。<span style=\"font-family: Calibri; font-size: 16px;\"><span style=\"font-family: 宋体; font-size: 18px;\"></span></span></span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "设备连接不上网络的可能因素有？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.路由器与设备的距离太远(尽量不超过<span style=\"font-family: Calibri;\">10</span>米，最大不超过<span style=\"font-family: Calibri;\">20)</span>或者设备与路由器之间存在干扰<span style=\"font-family: Calibri;\">WiFi</span>信号的阻隔物</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.WiFi名称以及密码有中文或者其他特殊符号 &nbsp;如￥<span style=\"font-family: Calibri; font-size: 16px;\">&amp;@ </span>等符号</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">3.WiFi密码填写错误</span></p><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">4.设备是否启动正常。无线连接时设备会发出语言提示，有线连接可以捂住摄像头光敏二极管看是否有红外灯亮起。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">5.路由器设置了桥连模式，会因为wifi信号不稳定，导致连接失败。不建议摄像机联网使用桥连模式的<span style=\"font-family: Calibri; font-size: 16px;\">wifi</span>。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">6.摄像机WiFi信号接收弱（设备故障），手机直连摄像机发出的热点，观看监控是否有卡顿的现象。</span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "设备对网络有什么要求？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">1.不支持5GWiFi及企业级WiFi</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">2.WiFi名称不能隐藏，路由器不能开启AP隔离，防蹭网等限制WiFi接入选项</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">3.不支持IPv6网络</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">4.确认摄像机不是连接光猫的WiFi（光猫的WiFi不稳定）</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">5.设备所连接的网络最好上行带宽4M以上。上行带宽：一般是指从你的电脑上传的速度，也就说别人从你的电脑进行通讯的速率！</span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "[4G系列产品]充值完成后，仍旧无法连接，一直显示连接中怎么办？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（1）请联系客服人员并提供卡号进行刷新。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（2）刷新卡号后，将APP首页的设备删除后，复位重启设备再尝试连接。</span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "[4G系列产品]刚买回来的设备就无法连接，或者使用了一段时间后突然连接不上怎么办？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（1）确认当前设备卡号是否还有流量套餐，没有流量套餐无法进行连接。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（2）若是未曾使用过体验套餐，可以联系客服人员重置套餐。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（3）若是卡信号在当地不好，可联系客服人员更换其他卡商的卡。</span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "[4G系列产品]设备提示离线怎么办？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（1）检查卡内是否有套餐，更换设备位置以寻找更好的信号。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（2）将设备复位重启后再进行连接。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（3）是否卡信号不好，可联系客服人员更换其他卡商的卡。</span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "因为二维码无法识别或损坏无法连接怎么办？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（1）WiFi版可以使用设备扫描手机二维码进行连接。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（2）任何型号的设备都可以联系客服人员，提供购买的型号和设备ID领取电子二维码。</span></p></li>\n" +
                            "</ol>"
                    },
                ]
            },
            {
                title: "故障排除",
                active: require("@/assets/images/birds/cn/icon-error-hover.png"),
                normal: require("@/assets/images/birds/cn/icon-error.png"),
                data:[
                    {
                        text: "摄像机在使用过程中出现掉线，卡顿，反应慢怎么处理？",
                        url: "/content/index",
                        content: "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.<span style=\"font-family: 宋体,SimSun; font-size: 18px;\">更新APP和摄像机固件到最新版本</span></span></p>\n" +
                            "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.检查摄像机连接的<span style=\"font-family: Calibri; font-size: 16px;\">WiFi</span>或者当前网络环境是否通畅，摄像机与路由器的距离是否过远。</span></p>\n" +
                            "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">3.<span style=\"font-family: 宋体,SimSun; font-size: 18px;\">复位摄像机重新配置网络。</span></span></p>\n" +
                            "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\"><span style=\"font-family: 宋体;\">4.摄像机<span style=\"font-family: Calibri;\">WiFi</span>信号接收弱（设备故障），手机直连摄像机发出的热点，观看监控是否有卡顿的现象，如有该现象需更换摄像</span><span style=\"font-family: 宋体;\">机。</span></span></p>"
                    },
                    {
                        text: "摄像机在局域网之内手机可以观看，外网观看不了？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.客户连接的是摄像机的热点，用4G网络或者其他WiFi就掉线了。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.客户是用是光猫设备，没有打开数据传输端口，可以让相应的运营商帮客户设置好光猫。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">3.修改路由器设置，把路由器的MTU（最大传输单元）值该为默认设置，然后重启路由器。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\"><span style=\"font-family: 宋体;\">4.设备和路由器断电重启，退出</span>APP<span style=\"font-family: 宋体;\">之后重新进入</span><span style=\"font-family: Calibri;\">APP</span><span style=\"font-family: 宋体;\">，确认设备是否可以正常监控。</span></span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">5.更新APP和摄像机固件到最新版本。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">6.复位设备重新添加</span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "APP打开对讲，摄像机这边没有发出声音?",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">1.苹果手机在 手机设置-千鸟物联-麦克风 开关要打开。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.安卓在</span> <span style=\"font-family: 宋体; font-size: 18px;\">手机设置</span>-<span style=\"font-family: 宋体; font-size: 18px;\">应用</span><span style=\"font-family: Calibri; font-size: 18px;\">-</span><span style=\"font-family: 宋体; font-size: 18px;\">权限管理</span><span style=\"font-family: Calibri; font-size: 18px;\">-</span><span style=\"font-family: 宋体; font-size: 18px;\">千鸟物联</span><span style=\"font-family: Calibri; font-size: 18px;\">-</span><span style=\"font-family: 宋体; font-size: 18px;\">麦克风</span>&nbsp;<span style=\"font-family: 宋体; font-size: 18px;\">要打开。</span></span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">3.设备喇叭坏了，开机有没有提示语言可以判断。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">4.<span style=\"font-family: 宋体; font-size: 18px;\">有该问题更新摄像机固件和</span>APP<span style=\"font-family: 宋体; font-size: 18px;\">版本</span></span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "夜间监控画面一片漆黑或者白天监控画面发红?",
                        url: "/content/index",
                        content: "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">夜间监控画面发黑，先检查摄像机这边红外灯是否打开，其次在红外灯开启的情况下给设备重新上电看是否切换到正常画面；白天监控画面发红，重启设备看是否切换过来，以及重启存在问题请联系卖家处理。</span></p>"
                    },
                    {
                        text: "APP打开监听，手机接收不到摄像机发出的声音?",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.手机声音没有打开，或者处于静音状态</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.摄像机咪头损坏</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\"><span style=\"font-family: 宋体;\">3.有该问题更新摄像机固件和</span>APP<span style=\"font-family: 宋体;\">版本</span></span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "相册里面的视频没有声音是什么原因？",
                        url: "/content/index",
                        content: "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">在设备监控页面进行屏幕录制的时候没有打开监听的功能</span></p>"
                    },
                    {
                        text: "摄像机SD卡录像没有录像文件？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.SD卡没有读取出来，断电重新插拔内存卡</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.SD卡损坏，更换新的内存卡</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">3.全时录像没有打开</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\"><span style=\"font-family: 宋体; font-size: 18px;\">4.观察监控时间是否为当前时间，如果时间不正确，请在app主页点击设备右上角设置，点击时区设置，选择对应时区后点击右上角保存即可。</span></span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">5.更新摄像机固件和APP版本</span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "APP上面无法读取到内存卡的容量",
                        url: "/content/index",
                        content: "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.内存卡损毁掉。</span></p>\n" +
                            "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\"><span style=\"font-family: 宋体;\">2.<span style=\"font-family: 宋体;\">内存卡格式不正确，需要到电脑上面使用</span></span><span style=\"color: rgb(51, 51, 51); letter-spacing: 0px; font-family: Helvetica; font-style: normal;\">FAT32</span><span style=\"color: rgb(51, 51, 51); letter-spacing: 0px; font-family: 宋体; font-style: normal;\">格式化内存卡。</span></span></p>\n" +
                            "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"color: rgb(51, 51, 51); letter-spacing: 0px; font-family: 宋体; font-size: 18px; font-style: normal;\">3.重新插拔内存卡。</span></p>"
                    },
                    {
                        text: "摄像机支持什么类型的内存卡",
                        url: "/content/index",
                        content: "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">速度：Class4及以上的都支持</span></p>\n" +
                            "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\"><span style=\"font-family: 宋体; font-size: 18px;\">容量</span>:<span style=\"font-family: 宋体; font-size: 18px;\">支持128GSD卡</span></span></p>\n" +
                            "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">类型：TF卡</span></p>\n" +
                            "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">分区格式：FAT32，<span style=\"font-family: Calibri; font-size: 16px;\">exFAT</span></span></p>"
                    },
                    {
                        text: "摄像机显示时间不对如何调整？",
                        url: "/content/index",
                        content: "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">在APP主页，点击设备右上角设置，点击时区设置，选择北京时区（或客户所在地区时区），点击保存即可。</span></p>\n"
                    },
                    {
                        text: "语音对讲无法使用怎么办？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（1）设备重启是否有声音，若是有，可以手动检查是否正确打开了录音权限或手机的声音。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（2）若是开机无声音，可联系客服人员更换设备。</span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "摄像头的灯突然不正常的闪烁怎么办？",
                        url: "/content/index",
                        content: "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">这是因为设备正在升级中，稍等升级完成即可。</span></p>\n"
                    },
                    {
                        text: "全时录像被分成一段一段的，这是正常的么？",
                        url: "/content/index",
                        content: "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">这是正常的，防止录像加载的时间过长。</span></p>\n"
                    },
                    {
                        text: "OPPO手机弹出系统弹窗提示不适配怎么办？",
                        url: "/content/index",
                        content: "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">无视即可，不会对使用造成影响。</span></p>\n"
                    },
                    {
                        text: "APP识别不到内存卡怎么办？",
                        url: "/content/index",
                        content: "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">内存卡可能掉出或者没有接触好，可以打开设备的后盖查看内存卡是否插好，确认内存卡是否正常插入。若插好格式化后还要断电重启设备一次。</span></p>\n"
                    },
                    {
                        text: "内存卡不显示，无录像，无回放怎么办？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（1）查看TF卡容量以及TF卡状态，建议使用16G及以上的内存卡。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（2）检查设备的时间是否同步，根据“故障排除”中的设置时间教程设置时间。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（3）设置好时间后，关闭APP再打开，将录像设置关闭后再重新开启录像。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（4）等待一段时间后如果还是没有回放则检查APP版本是否更新。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（5）如果APP为最新版本却还是没有录像，则到维护设备--格式化内存卡--重启设备，等一段时间看看是否有录像。</span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "内存卡满了怎么办？",
                        url: "/content/index",
                        content: "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">新的录像会将之前的录像覆盖掉，如果不放心也可以进行TF卡格式化。建议使用16G及以上内存卡。</span></p>\n"
                    },
                    {
                        text: "无法切换为夜视怎么办？",
                        url: "/content/index",
                        content: "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">检查是否开启智能模式或红外模式，全彩模式下会打开白光灯不会切换夜视。</span></p>\n"
                    },
                    {
                        text: "[4G系列产品]如果卡片丢失或者二维码损坏怎么办？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（1）卡片丢失，可以打开后盖取出流量卡查看。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（2）二维码损坏时可以联系客服人员并提供设备ID领取电子二维码。</span></p></li>\n" +
                            "</ol>"
                    },
                ]
            },
            {
                title: "功能使用",
                active: require("@/assets/images/birds/cn/icon-use-hover.png"),
                normal: require("@/assets/images/birds/cn/icon-use.png"),
                data: [
                    {
                        text: "如何复位摄像机",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p>1.在摄像机底部找到复位键或者复位孔，旁边会有 RESET 的英文字母。</p></li>\n" +
                            "    <li><p>2.长按复位键三秒或者用取卡针戳小孔三秒（取卡针在配件的螺丝包里面有哦），等待摄像机发出“复位成功”的语音提示即可完成复位步骤。</p></li>\n" +
                            "</ol>"
                    },
                    // {
                    //     text: "手机上面无法接收到报警推送消息",
                    //     url: "/content/index",
                    //     content: "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><strong><span style=\"font-family: 宋体; font-size: 24px;\"><span style=\"font-size: 20px;\"></span>苹果手机<span style=\"font-size: 24px;\"></span></span></strong></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">打开设置---千鸟物联<span style=\"font-family: Calibri;\">Mate---</span>通知 &nbsp;设置以下方式</span></p>\n" +
                    //         "        <p><img width='100%' title=\"5d312178c7d3c.png\" alt=\"5d312178c7d3c.png\" src=\""+require('@/assets/images/hapsee/cn/5d312178c7d3c.png')+"\"></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><strong><span style=\"font-family: 宋体; font-size: 24px;\">安卓手机<span style=\"font-size: 20px;\"></span></span></strong></p><p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 20px;\">Vivo和<span style=\"font-family: Calibri;\">OPPO</span>手机的设置</span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">VIVO<span style=\"font-family: 宋体;\">：</span></span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">1.<span style=\"font-family: 宋体; font-size: 16px;\">开启</span>APP<span style=\"font-family: 宋体; font-size: 16px;\">添加设备，在设置界面打开推送开关；</span></span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">2.<span style=\"font-family: 宋体; font-size: 16px;\">开启移动侦测并触发；</span></span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">3.<span style=\"font-family: 宋体; font-size: 16px;\">左键上划，弹出设置菜单，下拉</span>Mate<span style=\"font-family: 宋体; font-size: 16px;\">图标，点击锁，就设置为白名单；</span></span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">4.<span style=\"font-family: 宋体; font-size: 16px;\">返回</span>APP<span style=\"font-family: 宋体; font-size: 16px;\">，点击左键，弹出退出提示框，点击确定。</span></span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\"><span style=\"font-family: 宋体; font-size: 16px;\">设备可在关闭</span>APP<span style=\"font-family: 宋体; font-size: 16px;\">的情况下，接收推送；</span></span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">OPPO<span style=\"font-family: 宋体; font-size: 16px;\">：</span></span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">1.<span style=\"font-family: 宋体; font-size: 16px;\">开启</span>APP<span style=\"font-family: 宋体; font-size: 16px;\">添加设备，在设置界面打开推送开关；</span></span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">2.<span style=\"font-family: 宋体; font-size: 16px;\">开启移动侦测并触发；</span></span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">3.<span style=\"font-family: 宋体; font-size: 16px;\">进入设置</span>—&gt;<span style=\"font-family: 宋体; font-size: 16px;\">用用管理</span>—&gt;Mate—&gt;<span style=\"font-family: 宋体; font-size: 16px;\">通知管理：开启锁屏通知；</span></span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">4.<span style=\"font-family: 宋体; font-size: 16px;\">进入设置</span>—<span style=\"font-family: 宋体; font-size: 16px;\">应用管理</span>—&gt;Mate—&gt;<span style=\"font-family: 宋体; font-size: 16px;\">耗电保护：允许后台运行；</span></span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 18px;\">5.<span style=\"font-family: 宋体;\">允许自动启动；</span></span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 18px;\">6.<span style=\"font-family: 宋体; font-size: 18px;\">设置</span>—&gt;<span style=\"font-family: 宋体; font-size: 18px;\">电池</span>—&gt;<span style=\"font-family: 宋体; font-size: 18px;\">应用冻结（速冻）</span>—&gt;Mate<span style=\"font-family: 宋体; font-size: 18px;\">：关闭冻结；</span></span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 18px;\">7.<span style=\"font-family: 宋体; font-size: 18px;\">返回</span>APP<span style=\"font-family: 宋体; font-size: 18px;\">，邮件上划，弹出退出提示框，点击确定；</span></span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 20px;\">其他安卓手机接收不到推送时：</span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 18px;\">1.<span style=\"font-family: 宋体;\">首先进入设置页面，将推送关闭再打开一次，检查是否可接收推送；</span></span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 18px;\">2.<span style=\"font-family: 宋体; font-size: 18px;\">如果不能，退出</span>APP<span style=\"font-family: 宋体; font-size: 18px;\">，再次开启</span>APP<span style=\"font-family: 宋体; font-size: 18px;\">，检查是否会弹出推送警告提示框，如果有，点击设置，进入允许推送，之后再次确认是否可推送；</span></span></p>\n" +
                    //         "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 18px;\"><span style=\"font-family: 宋体; font-size: 18px;\">3.如果可以之后，请将</span>Mate<span style=\"font-family: 宋体; font-size: 18px;\">设置为白名单；（见上面</span>VIVO<span style=\"font-family: 宋体; font-size: 18px;\">白名单设置，</span>OPPO<span style=\"font-family: 宋体; font-size: 18px;\">允许后台运行和关闭冻结）</span></span></p>\n"
                    // },
                    {
                        text: "APP客户端支持那些手机系统？",
                        url: "/content/index",
                        content: "<p style=\"margin: 0pt;\"><span style=\"font-family: 宋体; font-size: 18px;\">支持Android4.0以上版本已及<span style=\"font-family: Calibri;\" times=\"\" new=\"\">iOS7.0</span>以上版本手机系统。</span></p>"
                    },
                    {
                        text: "本地录像的分享",
                        url: "/content/index",
                        content: "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">打开相册打开相片或者录像视频点击分享按键，可以分享到其他的手机APP软件（微信，QQ）。</span></p>"
                    },
                    {
                        text: "如何删除录像",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "            <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: Calibri; font-size: 14px; font-style: normal; font-weight: normal; margin-top: 0px; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.SD卡录像：全部删除是直接在设置页面选择<span style=\"font-family: Calibri;\">SD</span>卡格式化。</span></p></li>\n" +
                            "            <li><p style=\"color: rgb(0, 0, 0); font-family: Calibri; font-size: 14px; font-style: normal; font-weight: normal; margin-top: 0px; margin-bottom: 0px;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.本地录像：在相册里面选择摄像机目录下不需要的照片和视频选择删除掉。</span></p></li>\n" +
                            "        </ol>"
                    },
                    {
                        text: "如何分享设备？",
                        url: "/content/index",
                        content: "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">被分享者也必须下载并注册千鸟物联APP，分享者在APP首页点击摄像机的分享按钮，输入对方的账号或名称，被分享者同意即可。</span></p>\n"
                    },
                    {
                        text: "移动报警推送怎么打开？",
                        url: "/content/index",
                        content: "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">首先需要打开APP的通知权限，打开后打开APP中的报警功能中的报警推送，并将移动侦测调整到适合的距离，当有人经过时就会推送报警。</span></p>\n"
                    },
                    {
                        text: "如何更改清晰度？",
                        url: "/content/index",
                        content: "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">连接上设备后，进入查看时，点击全屏按钮后，就可以观察到超清或高清，点击即可修改。</span></p>\n"
                    },
                    {
                        text: "[4G系列产品]如何查看4G套餐内容？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（1）在千鸟物联APP中点击右下角我的，点击4G套餐充值，输入ICCID（卡号）后即可查看。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（2）可在APP主页面点击摄像机下方充值即可查看。</span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "[4G系列产品]如何查看设备ID和ICCID（卡号）？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（1）设备ID在机身上的二维码可以查看，ICCID在设备外部绑着的一张空卡片可以查看。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（2）如果手机APP绑定上设备可以直接点击设备设置按钮查看，如果没有绑定上就只能查看机身二维码或者外部空卡片。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">（3）也可以打开设备后盖，取出内部的流量卡查看ICCID。</span></p></li>\n" +
                            "</ol>"
                    },
                ]
            },
            {
                title: "云服务",
                active: require("@/assets/images/birds/cn/icon-cloud-hover.png"),
                normal: require("@/assets/images/birds/cn/icon-cloud.png"),
                data: [
                    // {
                    //     text: "如何分享设备给家人？",
                    //     url: "/content/index",
                    //     content: "<p>进入设备设置页面，找到【分享设备】，进入分享管理页面。</p>\n" +
                    //         "        <p>&nbsp;<img width='100%' title=\"5e634ec337d3b.png\" alt=\"5e634ec337d3b.png\" src=\""+require("@/assets/images/hapsee/cn/5e634ec337d3b.png")+"\"></p>\n" +
                    //         "        <p>用户可通过面对面扫码分享和输入账号分享两种方式将当前设备分享至其他账号.同一台设备最多可分享给5个分享账号，分享账号权限默认为：观看和回放录像，没有有设置权限。</p>\n" +
                    //         "        <p>&nbsp;<img width='100%' title=\"5e63551ee59b1.png\" alt=\"5e63551ee59b1.png\" src=\""+require("@/assets/images/hapsee/cn/5e63551ee59b1.png")+"\"></p>\n" +
                    //         "        <p>方法①：选择【面对面扫描分享】，APP生成设备分享二维码</p>\n" +
                    //         "        <p><img width='100%' title=\"5e6355845335a.png\" alt=\"5e6355845335a.png\" src=\""+require("@/assets/images/hapsee/cn/5e6355845335a.png")+"\"></p>\n" +
                    //         "        <p>使用另一部手机，登录其他账号后，点击【添加】—&gt;【分享添加摄像机】，并扫描上方的设备的分享二维码，即可添加到分享账号。<span style=\"color: rgb(255, 0, 0);\">说明：分享二维码有效期为10分钟，请在有效期内添加。</span></p>\n" +
                    //         "        <p><img width='100%' title=\"5e6355d3ef1b5.png\" alt=\"5e6355d3ef1b5.png\" src=\""+require("@/assets/images/hapsee/cn/5e6355d3ef1b5.png")+"\"></p>\n" +
                    //         "        <p><span style=\"font-family: 宋体;\"><br></span></p>\n" +
                    //         "        <p>方法②：选择【输入账号分享】，并输入要分享的账号，选择分享账号权限后，点击确定，就可以将设备分享至分享账号。</p>\n" +
                    //         "        <p><span style=\"font-family: 宋体;\"></span></p><p><img width='100%' title=\"5e63560eaaaed.png\" alt=\"5e63560eaaaed.png\" src=\""+require("@/assets/images/hapsee/cn/5e63560eaaaed.png")+"\"></p>"
                    // },
                    // {
                    //     text: "设备权限变了是为什么？怎样才能恢复设备的主账号权限？",
                    //     url: "/content/index",
                    //     content: "<p>注册并登录后，之前的摄像机会自动绑定到您的账号。如果有设备已经被其他账号绑定，则会显示为分享设备，分享设备缩略图右上角有[分享]字样。</p>\n" +
                    //         "        <p><span style=\"color: rgb(255, 0, 0);\">特别注意：分享设备可以观看和回放，但没有设置权限，请您使用主账号进行设置。</span></p>\n" +
                    //         "        <p><img width='100%' title=\"5e634e3a1147c.png\" alt=\"5e634e3a1147c.png\" src=\""+require("@/assets/images/hapsee/cn/5e634e3a1147c.png")+"\"></p>"
                    // },
                    // {
                    //     text: "忘记账号密码怎么办？",
                    //     url: "/content/index",
                    //     content: "<p><span style=\"font-family: 等线; font-size: 14px;\">&nbsp;开启APP，在登录页面右下角找到【忘记密码】，进入密码找回页面：</span></p>\n" +
                    //         "        <p><img width='100%' title=\"5e634d606b389.png\" alt=\"5e634d606b389.png\" src=\""+require("@/assets/images/hapsee/cn/5e634d606b389.png")+"\"></p>\n" +
                    //         "        <p><img width='100%' title=\"5e634d82a473b.png\" alt=\"5e634d82a473b.png\" src=\""+require("@/assets/images/hapsee/cn/5e634d82a473b.png")+"\"></p>\n" +
                    //         "        <p>输入要找回密码的手机/邮箱账号，并获取验证码后，点击下一步，之后就可设置新的账号密码。如下图。</p>\n" +
                    //         "        <p><img width='100%' title=\"5e634dbe12c04.png\" alt=\"5e634dbe12c04.png\" src=\""+require("@/assets/images/hapsee/cn/5e634dbe12c04.png")+"\"></p>"
                    // },
                    {
                        text: "同一时间仅支持几部手机登录？",
                        url: "/content/index",
                        content: "<p>同一个账号在同一时间仅支持一部手机登录，暂不支持多设备同时登录。如：在手机A已登录账号C的情况下，手机B登录账号C，那么手机A在下拉刷新设备列表或重新开启APP时会被强制注销登录。</p>\n" +
                            "         <p>账号内的网络摄像机，请使用【分享设备】功能分享给家人。</p>"
                    },
                    // {
                    //     text: "更新APP后，如何注册账号",
                    //     url: "/content/index",
                    //     content: "<p>1.在升级并开启APP后，会看到欢迎页面和登录界面，请根据注册引导点击登录界面左下角的【立即注册】，使用有效手机号或邮箱进行注册。</p>\n" +
                    //         "        <p><img width='100%' title=\"5e6342c1dc456.png\" alt=\"5e6342c1dc456.png\" src=\""+require("@/assets/images/hapsee/cn/5e6342c1dc456.png")+"\"></p>\n" +
                    //         "        <p><span style=\"font-family: 等线;\">2. App注册页面如下图所示：请按照页面要求进行注册，具体步骤如下：</span></p>\n" +
                    //         "        <p><span style=\"font-family: 等线;\"><span style=\"font-family: 等线; font-size: 14px;\">手机注册用户请选择国家或地区[如果是中国大陆地区可以忽略这一步]—&gt;输入有效手机号/邮箱—&gt;设置账号密码—&gt;点击【获取验证码】—&gt;输入有效验证码—&gt;勾选【请阅读并统一《用户协议》】—&gt;点击注册。</span></span></p>\n" +
                    //         "        <p><span style=\"font-family: 等线;\"><img width='100%' title=\"5e63438e512df.png\" alt=\"5e63438e512df.png\" src=\""+require("@/assets/images/hapsee/cn/5e63438e512df.png")+"\"></span></p>"
                    // },
                ]
            },
        ],
        // 操作视频
        tabRight: [
            // {text: "AP热点添加摄像机", playback: "http://player.youku.com/embed/XNDQ0ODc2MTY0NA=="},
            // {text: "网线添加摄像机", playback: "http://player.youku.com/embed/XNDQ0ODY2MDMxMg=="},
            // {text: "智能添加摄像机", playback: "http://player.youku.com/embed/XNDQ0ODY2MjQ2MA=="}
            {text: "4G监控产品连接", playback: "http://110.41.150.61:8085/file/video/cloudbirds1/千鸟物联4G监控产品视频指导.mp4"},
            {text: "太阳能监控", playback: "http://110.41.150.61:8085/file/video/cloudbirds1/太阳能监控视频指导.mp4"},
            {text: "WIFI常电分享设备", playback: "http://110.41.150.61:8085/file/video/cloudbirds1/WIFI常电分享设备.mp4"},
            {text: "报警录像设置", playback: "http://110.41.150.61:8085/file/video/cloudbirds1/报警录像设置.mp4"},
            {text: "查看回放和录像文件", playback: "http://110.41.150.61:8085/file/video/cloudbirds1/查看回放和录像文件.mp4"},
            {text: "购买云存储的理由", playback: "http://110.41.150.61:8085/file/video/cloudbirds1/购买云存储的理由.mp4"},
            {text: "开启录像和查看录像", playback: "http://110.41.150.61:8085/file/video/cloudbirds1/开启录像和查看录像.mp4"},
            {text: "录像模式设置", playback: "http://110.41.150.61:8085/file/video/cloudbirds1/录像模式设置.mp4"},
            {text: "设备音量调节", playback: "http://110.41.150.61:8085/file/video/cloudbirds1/设备音量调节.mp4"},
            {text: "摄像机对讲设置", playback: "http://110.41.150.61:8085/file/video/cloudbirds1/摄像机对讲设置.mp4"},
            {text: "双光源介绍", playback: "http://110.41.150.61:8085/file/video/cloudbirds1/双光源介绍.mp4"},
            {text: "云存储购买和使用", playback: "http://110.41.150.61:8085/file/video/cloudbirds1/云存储购买和使用.mp4"},
        ],
        footer: "Copyright © 2016 - 2023 Cloudbirds All Rights Reserved."
    },
}
