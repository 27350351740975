module.exports = {
    message: {
        app: "keepeyes",
        language: "zh",
        color: "#00959D",
        search: "有问题搜一下",
        feedback: "问题反馈",
        // 轮播图
        swipe: [
            {image: require("@/assets/images/keepeyes/cn/5f16bc2285a0f.jpg"), playback: "#"}
        ],
        tabTitle:{
            left: "常见问题解答",
            right: "操作视频",
        },
        contentImage: "",
        // 常见问题解答
        tabLeft: [
            {
                title: "添加设备",
                active: require("@/assets/images/hapsee/cn/page01_hover.png"),
                normal: require("@/assets/images/hapsee/cn/page01.png"),
                data: [
                    {
                        text: "HomeBase如何添加摄像头？",
                        url: "/content/index",
                        content: "<p>HOMEBASE接通局域网电源，按顶部按钮3秒，绿灯闪烁，语音提示进入配对模式。</p>\n" +
                            "<p><br></p>\n" +
                            "<p>按三次摄像头底部的按钮，红蓝灯交替闪烁。HOMEBASE上出现一个语音提示，表明配对成功，添加成功；</p>"
                    },
                    {
                        text: "如何与家人分享设备？",
                        url: "/content/index",
                        content: "<p>用户可以输入一个帐户来共享当前设备给其他帐户。同一设备最多可以共享五个共享账号，共享账号的默认权限是观看和回放视频，不需要设置权限。</p>"
                    },
                    {
                        text: "APP更新后如何注册账号",
                        url: "/content/index",
                        content: "<p>1. 升级并打开APP后，您将看到欢迎页面和登录界面。请根据注册指南点击登录界面左上角的“立即注册”，使用有效的电话号码或邮箱进行注册。</p>\n" +
                            "<p>2. App注册页面如下图所示:请按照页面要求进行注册，具体步骤如下:</p>\n" +
                            "<p>手机注册,请选择国家或地区- - - &gt;输入有效的手机号码/邮件——&gt;设置账户密码- &gt;点击【获取验证码】- &gt;输入有效的验证码- &gt;标记(请阅读和统一用户协议)——&gt;点击注册。</p>\n"
                    }
                ]
            },
            {
                title: "故障排除",
                active: require("@/assets/images/hapsee/cn/page02_hover.png"),
                normal: require("@/assets/images/hapsee/cn/page02.png"),
                data:[
                    {
                        text: "如何处理HomeBase在使用过程中掉线和卡顿?",
                        url: "/content/index",
                        content: "<p>1. 更新应用程序和相机固件到最新版本</p>\n" +
                            "<p>2. 检查HomeBase连接的WiFi或当前的网络环境是否顺畅，以及HomeBase与路由器的距离是否太远。</p>\n" +
                            "<p>3.重置基地以重新配置网络。</p>\n" +
                            "<p>4. HomeBaseWiFi信号接收较弱(设备故障)，手机直接连接摄像头发出的热点，观察监控是否有卡顿现象。</p>"
                    },
                    {
                        text: " 扫描二维码添加HomeBase失败？",
                        url: "/content/index",
                        content: "<p>1.请确认HomeBase端口已经连接到路由器上的LAN端口，并且HomeBase设备顶部中间的LED灯已经打开并变为蓝色。</p>\n" +
                            "<p>2.请确保您的手机连接的WIFI路由器与HomeBase设备相同。</p>\n" +
                            "<p>3.请确保您已经扫描了HomeBase设备底部的二维码。</p>"
                    },
                    {
                        text: "摄像头可以在局域网内查看，但不能在外部网络上查看?",
                        url: "/content/index",
                        content: "<p>1. 设备和路由器关机重启，退出APP重新进入APP，确认设备是否可以正常监控。</p>\n" +
                            "<p>2. 更新应用程序和相机固件到最新版本。</p>\n" +
                            "<p>3.重置设备并再次添加</p>"
                    }
                ]
            },
            {
                title: "功能使用",
                active: require("@/assets/images/hapsee/cn/page03_hover.png"),
                normal: require("@/assets/images/hapsee/cn/page03.png"),
                data: [
                    {
                        text: "如何分享摄像头？",
                        url: "/content/index",
                        content: "<p>1.进入网关设置列表，找到共享摄像头;</p>\n" +
                            "<img title=\"5efae9cf03b89.png\" alt=\"5efae9cf03b89.png\" src=\""+require("@/assets/images/keepeyes/cn/5efae9cf03b89.png")+"\" style=\"white-space: normal; width: 100%;\">\n" +
                            "<p>2. 用户可以通过输入一个账号将当前的摄像头分享给其他账号，同一摄像头最多可以分享给5个共享账号。帐号权限:观看和重播视频，或仅观看视频;</p>"
                    },
                    {
                        text: "如何播放，下载和删除回放录像？",
                        url: "/content/index",
                        content: "<p>点击实时预览界面的“视频播放图标”，进入单独播放界面，时间轴播放，文件管理下载和删除;全屏播放。</p>\n" +
                            "<img title=\"5f097766316ea.png\" alt=\"5f097766316ea.png\" src=\""+require("@/assets/images/keepeyes/cn/5f097766316ea.png")+"\" style=\"white-space: normal;\">"
                    },
                    {
                        text: "如何将HomeBase复位？",
                        url: "/content/index",
                        content: "<p>1. 找到一个重置键或重置洞在底部的基地与英文字母重置旁边。</p>\n" +
                        "<p>2. 长按复位按钮3秒或用取卡针戳钥匙孔3秒(取卡针在配件螺杆袋中找到)，等待HomeBase发出“复位成功”语音提示完成复位步骤。</p>\n" +
                        "<p><img src=\""+require("@/assets/images/keepeyes/cn/5fd0829642d98.png")+"\" title=\"5fd0829642d98.png\" alt=\"5fd0829642d98.png\" style=\"width: 100%;\"></p>"
                    },
                    {
                        text: "如何识别共享设备",
                        url: "/content/index",
                        content: "<p>在您注册并登录后，使用分享功能将设备分享给您的家人或朋友的账号上，在设备列表此摄像头右上角会显示“分享”黄色图标；</p>"
                    },
                ]
            },
            {
                title: "云服务",
                active: require("@/assets/images/hapsee/cn/page04_hover.png"),
                normal: require("@/assets/images/hapsee/cn/page04.png"),
                data: []
            },
        ],
        // 操作视频
        tabRight: [],
        footer: "Copyright © 2016 - 2023 Keep Eyes All Rights Reserved."
    },
}
