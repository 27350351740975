module.exports = {
    message: {
        app: "keepeyes",
        language: "en",
        color: "#00959D",
        search: "Have a problem to search",
        feedback: "Feedback",
        // 轮播图
        swipe: [
            {image: require("@/assets/images/keepeyes/en/5f16bc4bdab52.jpg"), playback: "#"},
        ],
        tabTitle:{
            left: "Problem Solving",
            right: "Operational Video",
        },
        contentImage: "",
        // 常见问题解答
        tabLeft: [
            {
                title: "Add Device",
                active: require("@/assets/images/hapsee/cn/page01_hover.png"),
                normal: require("@/assets/images/hapsee/cn/page01.png"),
                data: [
                    {
                        text: "How to register an account after updating the APP",
                        url: "/content/index",
                        content: "<p>1. After upgrading and opening the APP, you will see the welcome page and login interface. Please click \"Register now\" in the upper left corner of the login interface according to the registration guide and register with a valid phone number or email.</p>\n" +
                            "<p>2. The App registration page is shown in the figure below: Please register according to the requirements of the page, and the specific steps are as follows:</p>\n" +
                            "<p>For mobile phone registration, please select the country or region -- &gt; enter a valid mobile phone number/email -- &gt; set the account password -- &gt; click [to get verification code] -- &gt; enter a valid verification code -- &gt; tick [please read and unify the user agreement] -- &gt; click to register.</p>"
                    },
                    {
                        text: "How do you share it with your family",
                        url: "/content/index",
                        content: "<p>Users can input an account to share the current device to other accounts. The same device can be Shared to up to five sharing accounts, and the default permissions for sharing accounts are to watch and replay videos without set permissions.</p>"
                    },
                    {
                        text: "HomeBase adds cameras",
                        url: "/content/index",
                        content: "<p>HOMEBASE makes a power connection to a LAN, presses the button at the top for three seconds, and a green light flashes and a voice prompt goes into pairing mode.</p>\n" +
                            "<p>Press the button at the bottom of the camera three times, the red and blue lights flash alternately. A voice prompt appears at HOMEBASE indicating that the pairing was successful, indicating that the addition was successful</p>"
                    },
                    {
                        text: "How to add HomeBase?",
                        url: "/content/index",
                        content: "<p>1.HomeBase connects to the LAN port and power of the router. Please use the original power adapter in the package.</p>\n" +
                            "<p>2. Connect your phone to the same Wi-Fi router HomeBase connects to.</p>\n" +
                            "<p>3. Please wait until the LED in the middle of the top of the HameBase device turns on and turns blue.</p>\n" +
                            "<p>4. Click the button in the upper right corner of the app to scan the QR code at the bottom of HomeBase.</p>\n" +
                            "<p>5. After scanning the page, jump to Finish means that the addition is successful;</p>"
                    },
                    {
                        text: "If you can not connect your camera with Wi-Fi successfully, please confirm",
                        url: "/content/index",
                        content: "<p style=\"margin: 0pt;\"><span style=\"font-family: \" calibri=\"\" font-size:=\"\" mso-spacerun:=\"\" mso-fareast-font-family:=\"\" mso-hansi-font-family:=\"\" mso-bidi-font-family:=\"\" mso-font-kerning:=\"\"><span style=\"font-family: 宋体;\">①</span></span><span style=\"font-family: 宋体; font-size: 12pt; mso-spacerun: \" mso-hansi-font-family:=\"\" mso-bidi-font-family:=\"\" times=\"\" new=\"\" mso-font-kerning:=\"\" mso-ascii-font-family:=\"\" calibri=\"\">. Your home Wi-Fi network is 2 4G. Our camera does not support 5G Wi-Fi network. Please check your Wi-Fi Frequency band of the Wi-Fi router, or search its brand and model on google.</span></p>\n" +
                            "<p style=\"margin: 0pt;\"><span style=\"font-family: \" calibri=\"\" font-size:=\"\" mso-spacerun:=\"\" mso-fareast-font-family:=\"\" mso-hansi-font-family:=\"\" mso-bidi-font-family:=\"\" mso-font-kerning:=\"\"><span style=\"font-family: 宋体;\">②</span></span><span style=\"font-family: 宋体; font-size: 12pt; mso-spacerun: \" mso-hansi-font-family:=\"\" mso-bidi-font-family:=\"\" times=\"\" new=\"\" mso-font-kerning:=\"\" mso-ascii-font-family:=\"\" calibri=\"\">. If your router is dual band, please choose 2.4G Wi-Fi to connect your phone and the camera.</span></p>\n" +
                            "<p style=\"margin: 0pt;\"><span style=\"font-family: \" calibri=\"\" font-size:=\"\" mso-spacerun:=\"\" mso-fareast-font-family:=\"\" mso-hansi-font-family:=\"\" mso-bidi-font-family:=\"\" mso-font-kerning:=\"\"><span style=\"font-family: 宋体;\">③</span></span><span style=\"font-family: 宋体; font-size: 12pt; mso-spacerun: \" mso-hansi-font-family:=\"\" mso-bidi-font-family:=\"\" times=\"\" new=\"\" mso-font-kerning:=\"\" mso-ascii-font-family:=\"\" calibri=\"\">. Please put this Wi-Fi camera more close to the router.</span></p>\n" +
                            "<p style=\"margin: 0pt;\"><span style=\"font-family: \" calibri=\"\" font-size:=\"\" mso-spacerun:=\"\" mso-fareast-font-family:=\"\" mso-hansi-font-family:=\"\" mso-bidi-font-family:=\"\" mso-font-kerning:=\"\"><span style=\"font-family: 宋体;\">④</span></span><span style=\"font-family: 宋体; font-size: 12pt; mso-spacerun: \" mso-hansi-font-family:=\"\" mso-bidi-font-family:=\"\" times=\"\" new=\"\" mso-font-kerning:=\"\" mso-ascii-font-family:=\"\" calibri=\"\">. Please check if the Wi-Fi router is operating properly first, then turn on the DHCP of router, and set up WAP2-PSK for security recognized by the camera.</span></p>\n" +
                            "<p style=\"margin: 0pt;\"><span style=\"font-family: \" calibri=\"\" font-size:=\"\" mso-spacerun:=\"\" mso-fareast-font-family:=\"\" mso-hansi-font-family:=\"\" mso-bidi-font-family:=\"\" mso-font-kerning:=\"\"><span style=\"font-family: 宋体;\">⑤</span></span><span style=\"font-family: 宋体; font-size: 12pt; mso-spacerun: \" mso-hansi-font-family:=\"\" mso-bidi-font-family:=\"\" times=\"\" new=\"\" mso-font-kerning:=\"\" mso-ascii-font-family:=\"\" calibri=\"\">. If camera still fails to connect with wifi, please reset camera and reconnect it.</span></p>"
                    }
                ]
            },
            {
                title: "Troubleshooting",
                active: require("@/assets/images/hapsee/cn/page02_hover.png"),
                normal: require("@/assets/images/hapsee/cn/page02.png"),
                data:[
                    {
                        text: "The camera can be viewed on the mobile phone within the LAN, but not on the external network？",
                        url: "/content/index",
                        content: "<p>1. The device and router power off and restart, exit the APP and re-enter the APP to confirm whether the device can be monitored normally.</p>\n" +
                            "<p>2. Update APP and camera firmware to the latest version.</p>\n" +
                            "<p>3. Reset the device and add it again</p>"
                    },
                    {
                        text: "Scan code to add HomeBase failed",
                        url: "/content/index",
                        content: "<p>1.<span style=\"white-space: pre;\"></span>Please make sure the HomeBase port is connected to the LAN port on the router and LED light in the middle of the top of HomeBase device has been turned on and changed to blue.</p>\n" +
                            "<p>2.<span style=\"white-space: pre;\"></span>Please make sure your cellphone is connected to the same WIFI router that connects the HomeBase device.</p>\n" +
                            "<p>3.<span style=\"white-space: pre;\"></span>Please make sure that you have scanned the QR code at the bottom of the HomeBase device.</p>"
                    },
                    {
                        text: "How to deal with HomeBase dropped, stuck during use?",
                        url: "/content/index",
                        content: "<p>1. Update APP and camera firmware to the latest version</p>\n" +
                            "<p>2. Check whether the WiFi connected by HomeBase or the current network environment is smooth, and the distance between HomeBase and the router is too far.</p>\n" +
                            "<p>3. Reset HomeBase to reconfigure the network.</p>\n" +
                            "<p>4. The HomeBaseWiFi signal reception is weak (device failure), the mobile phone is directly connected to the hot spot emitted by the camera, and watch for monitoring whether there is a phenomenon of stuttering.</p>"
                    },
                    {
                        text: "What if I can't get the captcha?",
                        url: "/content/index",
                        content: "<p>1. The mobile phone cannot receive the CAPTCHA: Please check whether the SMS interception function is enabled, and check whether there is a CAPtcha message in the intercepted SMS.</p>\n" +
                            "<p>2. Verification code cannot be received in the mailbox: Please check whether there is verification code mail in the garbage can.</p>\n" +
                            "<p>If you do not receive it, try sending the verification code again. If not, please feedback to our technical staff will help you solve.</p>"
                    },
                    {
                        text: "Stay tuned for our",
                        url: "/content/index",
                        content: "<p>Stay tuned for our</p>"
                    }
                ]
            },
            {
                title: "Function Use",
                active: require("@/assets/images/hapsee/cn/page03_hover.png"),
                normal: require("@/assets/images/hapsee/cn/page03.png"),
                data: [
                    {
                        text: "How to identify Shared devices",
                        url: "/content/index",
                        content: "<p>After you register and log in, the previous camera will automatically bind to your account. If a device has been bound by another account, it will be shown as a Shared device with the word \"share\" in the upper right corner of the thumbnail of the Shared device.</p>"
                    },
                    {
                        text: "How do you reset HomeBase？",
                        url: "/content/index",
                        content: "<p>1. Find a RESET key or a RESET hole at the bottom of HomeBase with the English letter RESET next to it.</p>\n" +
                            "<p>2. Long press the reset button for three seconds or poke the keyhole with the card-taking needle for three seconds (the card-taking needle is found in the screw bag of accessories), and then wait for HomeBase to issue a voice prompt of \"reset successfully\" to complete the reset step.</p>"
                    },
                    {
                        text: "Playback and file download",
                        url: "/content/index",
                        content: "<p>&nbsp;Click the \"Video Playback icon\" in the real-time preview interface to enter the separate playback interface, perform timeline playback, file management download and delete; Full screen playback.</p>\n" +
                            "<p><img title=\"5f097766316ea.png\" alt=\"5f097766316ea.png\" src=\""+require("@/assets/images/keepeyes/cn/5f097766316ea.png")+"\"></p>"
                    },
                    {
                        text: "share camera",
                        url: "/content/index",
                        content: "<p>Go to the gateway Settings list and find the Shared camera；</p>\n" +
                            "<p><img title=\"5efae9cf03b89.png\" style=\"width: 100%;\" alt=\"5efae9cf03b89.png\" src=\""+require("@/assets/images/keepeyes/cn/5efae9cf03b89.png")+"\"><br></p>\n" +
                            "<p>2. Users can share the current camera to other accounts by entering an account, and the same camera can be Shared to up to 5 sharing accounts. Account rights: watch and replay videos, or only watch videos;</p>"
                    },
                    {
                        text: "How do I reset HomeBase？",
                        url: "/content/index",
                        content: "<p>Find the reset hole at the bottom of HomeBase;</p>\n" +
                            "<p>Hold the reset hole for five seconds and wait for the HomeBase to give a voice prompt that the reset is successful.</p>\n" +
                            "<p><img src=\""+require("@/assets/images/keepeyes/cn/5fd0829642d98.png")+"\" title=\"5fd0829642d98.png\" alt=\"5fd0829642d98.png\" style=\"width: 100%;\"></p>"
                    },
                    {
                        text: "Stay tuned for our",
                        url: "/content/index",
                        content: "<p>Stay tuned for our</p>"
                    }
                ]
            },
            {
                title: "Cloud Service",
                active: require("@/assets/images/hapsee/cn/page04_hover.png"),
                normal: require("@/assets/images/hapsee/cn/page04.png"),
                data: []
            },
        ],
        // 操作视频
        tabRight: [
            {text: "Setup Camera by Sound", playback: "https://youtu.be/61Gj4C7nZek"},
            {text: "Video Viewing without Network", playback: "https://youtu.be/IWwmzS9HwWY"},
            {text: "Add Device by AP Hotspot", playback: "https://youtu.be/Ud7PNq35MKY"},
        ],
        footer: "Copyright © 2016 - 2023 Keep Eyes All Rights Reserved."
    },
}
