import { createRouter, createWebHistory } from 'vue-router'

import BaseLayout from '@/components/BaseLayout'

export const constantRoutes = [
  {
    path: '/',
    component: BaseLayout,
    redirect: 'first',
    children: [{
      name: 'LeftList',
      path: 'first',
      component: () => import('@/views/leftlist/index'),
    }]
  },
  {
    path: '/dist',
    component: BaseLayout,
    redirect: 'first',
    children: [{
      name: 'LeftList',
      path: 'first',
      component: () => import('@/views/leftlist/index'),
    }]
  },

  {
    path: '/dist/lang/index',
    component: BaseLayout,
    children: [{
      name: 'Lang',
      path: '/dist/lang/index',
      component: () => import('@/views/leftlist/index'),
    }]
  },

  {
    path: '/dist/content',
    component: BaseLayout,
    children: [{
      name: 'Content',
      path: 'index/:data',
      component: () => import('@/views/content/index'),
    }]
  },

  {
    name: 'Form',
    path: '/dist/form',
    component: () => import('@/views/form/index'),
  },
  {
    name: 'Contact',
    path: '/dist/contact',
    component: () => import('@/views/other/contact'),
  },
  // 404 page must be placed at the end !!!
  // { path: '*', redirect: '/404', hidden: true }
]

const create = () => createRouter({
  // mode: 'history', // require service support
  base: '/dist/',
  history: createWebHistory(),
  routes: constantRoutes
})

const router = create()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = create()
  router.matcher = newRouter.matcher // reset router
}

export default router
