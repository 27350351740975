import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n'
import router from './router'

// 引入vant组件样式
import 'vant/lib/index.css';
// 引入移动端适配
// import 'lib-flexible/flexible.js'

var prefix = 'hapsee'
var suffix = 'cn'
// var prefix = 'birds'
// var suffix = 'cn'
// var prefix = 'keepeyes'
// var suffix = 'cn'
router.beforeEach((to, from, next) => {
    if (to.path == '/first'){
        if (to.query.appkey != undefined){
            if (to.query.appkey.toLocaleLowerCase() == "com.dayunlinks.cloudbirds"){
                prefix = 'birds'
            }else if (to.query.appkey.toLocaleLowerCase() == "com.dayunlinks.hapseemate") {
                prefix = 'hapsee'
            }else {
                prefix = 'keepeyes'
            }
        }
        if (to.query.lang != undefined){
            if (to.query.lang.toLocaleLowerCase().includes("zh") || to.query.lang.toLocaleLowerCase().includes("cn")){
                suffix = 'cn'
            }else {
                suffix = 'en'
            }
        }
        next({
            path:"/dist/lang/index",
            query:{
                id: prefix + '-' + suffix
            }
        })
    }

    next()
})

// 通过选项创建 VueI18n 实例
const i18n = new createI18n({
    locale: prefix + '-' + suffix, // 设置地区
    messages: {
        'birds-cn': require('@/common/language/cn/birds'),
        'birds-en': require('@/common/language/en/birds'),
        'hapsee-cn': require('@/common/language/cn/hapsee'),
        'hapsee-en': require('@/common/language/en/hapsee'),
        'keepeyes-cn': require('@/common/language/cn/keepeyes'),
        'keepeyes-en': require('@/common/language/en/keepeyes')
    }, // 设置地区信息
})

const app = createApp(App)
app.use(i18n)
app.use(router)
app.mount('#app');