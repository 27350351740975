import "core-js/modules/es.array.push.js";
import { ref } from 'vue';
export default {
  name: 'BaseLayout',
  computed: {
    key() {
      return this.$route.path;
    }
  },
  mounted() {
    if (this.$route.query.id != undefined) {
      this.$i18n.locale = this.$route.query.id;
      this.tabLeftTitle = this.$tm(`message.tabLeft`) || [];
      this.tabLeftData = this.$tm(`message.tabLeft`)[0].data || [];
      this.tabRightData = this.$tm(`message.tabRight`) || [];
    }
  },
  data() {
    return {
      value: '',
      active: ref(0),
      tabLeftTitle: this.$tm(`message.tabLeft`) || [],
      tabLeftData: this.$tm(`message.tabLeft`)[0].data || [],
      tabRightData: this.$tm(`message.tabRight`) || []
    };
  },
  methods: {
    onSearch: function (val) {
      if ("" != val) {
        let tabLeft = JSON.parse(JSON.stringify(this.$tm(`message.tabLeft`))); //转换后更新不改变原数组
        let newArr = [];
        tabLeft.forEach(item => {
          item.data = item.data.filter(t => t.text.includes(val));
          if (item.data.length != 0) {
            item.data.forEach(t => {
              newArr.push(t);
            });
          }
        });
        this.tabLeftTitle = [];
        this.tabLeftData = newArr;
        if (newArr.length != 0) {
          this.active = ref(0);
          this.$router.push("/dist/lang/index");
        } else {
          let tabRight = this.$tm(`message.tabRight`);
          this.tabRightData = tabRight.filter(item => item.text.includes(val));
          if (this.tabRightData != 0) {
            this.active = ref(1);
          }
        }
      } else {
        this.tabLeftTitle = this.$tm(`message.tabLeft`);
        this.tabLeftData = this.$tm(`message.tabLeft`)[0].data;
        this.$router.push("/dist/lang/index");
      }
    },
    tabHandler: function (index) {
      this.value = "";
      if (index == 0) {
        this.tabLeftTitle = this.$tm(`message.tabLeft`);
        this.tabLeftData = this.$tm(`message.tabLeft`)[0].data;
        this.$router.push("/dist/lang/index");
      } else {
        this.tabRightData = this.$tm(`message.tabRight`);
      }
    },
    toContant: function (value) {
      window.location.href = value;
    }
  }
};