module.exports = {
    message: {
        app: "hapsee",
        language: "zh",
        color: "#00959D",
        search: "有问题搜一下",
        feedback: "问题反馈",
        // 轮播图
        swipe: [
            {image: require("@/assets/images/hapsee/cn/5db26c4c127fb.jpg"), playback: "http://player.youku.com/embed/XNDQ0ODc2MTY0NA=="},
            {image: require("@/assets/images/hapsee/cn/5db25d64a19c5.jpg"), playback: "http://player.youku.com/fembed/XNDQ0ODY2MDMxMg=="},
            {image: require("@/assets/images/hapsee/cn/5e63671fb71b9.jpg"), playback: "#"}
        ],
        tabTitle:{
            left: "常见问题解答",
            right: "操作视频",
        },
        contentImage: require("@/assets/images/hapsee/cn/sm02.png"),
        // 常见问题解答
        tabLeft: [
            {
                title: "添加设备",
                active: require("@/assets/images/hapsee/cn/page01_hover.png"),
                normal: require("@/assets/images/hapsee/cn/page01.png"),
                data: [
                    {
                        text: "APP更新之后登录页面的账号和密码在什么地方",
                        url: "/content/index",
                        content: "<p>APP更新到最新版本之后，需要您给APP注册一个账号和密码，注册步骤如下图</p>\n" +
                            "<p><img width='100%' title=\"5e6077f5c9ab5.png\" src=\""+require('@/assets/images/hapsee/cn/5e6077f5c9ab5.png')+"\"></p>\n" +
                            "<p><img width='100%' title=\"5e6077fa79a46.png\" src=\""+require('@/assets/images/hapsee/cn/5e6077fa79a46.png')+"\"></p>"
                    },
                    {
                        text: "如何修改密码？",
                        url: "/content/index",
                        content: "<p>需要摄像机密码的情况</p>" +
                            "<ol style=\"list-style-type: none;\">\n" +
                            "    <li>1.第二台手机添加摄像机需要摄像机的ID号和密码，如果忘记密码可以通过第一台手机的分享码，扫描添加摄像机。</li>\n" +
                            "    <li>2.在APP里面不小心删除了设备栏里面连接的设备，重新添加的时候需要密码——复位摄像机<span style=\"font-size: 18px;\">(复位步骤见“常见问题解答”）</span>，等待摄像机发出配网提示音，重新给摄像机配网，添加摄像机成功之后即可修改密码。</li>\n" +
                            "    <li>3.需要修改摄像机密码忘记旧密码——忘记密码的情况下在APP里面是无法找回旧密码，只有复位摄像机<span style=\"font-size: 18px;\">(复位步骤见“常见问题解答”）</span>，等待摄像机发出配网提示音，重新给摄像机配网，添加摄像机成功之后即可修改密码。</li>\n" +
                            "</ol>"
                    },
                    {
                        text: "摄像机连接视频",
                        url: "/content/index",
                        content: "<p><strong><span style=\"font-size: 18px;\">热点添加摄像机</span></strong></p>\n" +
                            "<p><video style='width: 100%;' class=\"edui-upload-video  vjs-default-skin  video-js\" controls=\"\" preload=\"none\" width=\"390\" height=\"300\" src=\""+require('@/assets/images/hapsee/cn/5d37b7aac60c3.mp4')+"\" data-setup=\"{}\"><source src=\""+require('@/assets/images/hapsee/cn/5d37b7aac60c3.mp4')+"\" type=\"video/mp4\"></video></p>\n" +
                            "<p><strong><span style=\"font-size: 18px;\">网线添加摄像机</span></strong></p>\n" +
                            "<p><video style='width: 100%;' class=\"edui-upload-video  vjs-default-skin    video-js\" controls=\"\" preload=\"none\" width=\"390\" height=\"280\" src=\""+require('@/assets/images/hapsee/cn/5d37b694123f2.mp4')+"\" data-setup=\"{}\"><source src=\""+require('@/assets/images/hapsee/cn/5d37b694123f2.mp4')+"\" type=\"video/mp4\"></video></p>\n" +
                            "<p><strong><span style=\"font-size: 18px;\">声波添加摄像机</span></strong></p>\n" +
                            "<p><video style='width: 100%;' class=\"edui-upload-video  vjs-default-skin    video-js\" controls=\"\" preload=\"none\" width=\"390\" height=\"280\" src=\""+require('@/assets/images/hapsee/cn/5d37b6b793f7b.mp4')+"\" data-setup=\"{}\"><source src=\""+require('@/assets/images/hapsee/cn/5d37b6b793f7b.mp4')+"\" type=\"video/mp4\"></video></p>"
                    },
                    {
                        text: "摄像机连接成功之后提示密码错误怎么处理？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.复位摄像机(复位步骤见“常见问题解答”），删除APP设备栏里面的设备，重新配置摄像机连接的WiFi。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.多次复位摄像机，添加成功之后还是密码错误。把摄像机ID号提供给客服人员或者发送邮箱到fae@dayunlinks.com</span><span style=\"font-family: 宋体; font-size: 18px;\">，我们会及时联系处理。</span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "设备提示“连接完成，欢迎使用”之后，设备栏设备显示离线",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.重启路由器之后复位设备，删除设备栏离线设备，根据语音提示重新添加摄像机。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.换网络之后重新添加摄像机：换一个wifi重新添加摄像机，比如可以打开另外一台手机的手机热点，以此网络为摄像机所要连接的<span style=\"font-family: Calibri; font-size: 16px;\"><span style=\"font-family: 宋体; font-size: 18px;\">wifi。</span></span></span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "设备连接不上网络的可能因素有？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.路由器与设备的距离太远(尽量不超过<span style=\"font-family: Calibri;\">10</span>米，最大不超过<span style=\"font-family: Calibri;\">20)</span>或者设备与路由器之间存在干扰<span style=\"font-family: Calibri;\">WiFi</span>信号的阻隔物</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.WiFi名称以及密码有中文或者其他特殊符号 &nbsp;如￥<span style=\"font-family: Calibri; font-size: 16px;\">&amp;@ </span>等符号</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">3.WiFi密码填写错误</span></p><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">4.设备是否启动正常。无线连接时设备会发出语言提示，有线连接可以捂住摄像头光敏二极管看是否有红外灯亮起。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">5.路由器设置了桥连模式，会因为wifi信号不稳定，导致连接失败。不建议摄像机联网使用桥连模式的<span style=\"font-family: Calibri; font-size: 16px;\">wifi</span>。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">6.摄像机WiFi信号接收弱（设备故障），手机直连摄像机发出的热点，观看监控是否有卡顿的现象。</span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "设备对网络有什么要求？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">1.不支持5GWiFi及企业级WiFi</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">2.WiFi名称不能隐藏，路由器不能开启AP隔离，防蹭网等限制WiFi接入选项</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">3.不支持IPv6网络</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\">4.确认摄像机不是连接光猫的WiFi（光猫的WiFi不稳定）</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">5.设备所连接的网络最好上行带宽4M以上。上行带宽：一般是指从你的电脑上传的速度，也就说别人从你的电脑进行通讯的速率！</span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "电脑客户端下载添加方式",
                        url: "/content/index",
                        content: "<p style=\"margin: 0pt; text-align: left;\"><span style=\"color: rgb(255, 0, 0); font-size: 18px;\">注意:以下摄像机添加方式分两种，局域网添加和手动添加，向下翻阅查找。</span></p>\n" +
                            "<p style=\"margin: 0pt; text-align: left;\"><span style=\"font-size: 24px;\">一.下载方式</span></p>\n" +
                            "<p style=\"margin: 0pt; text-align: left;\"><span style=\"font-size: 24px;\"><span style=\"font-family: 宋体; font-size: 24px;\"><span style=\"font-family: 宋体; font-size: 16px;\"><span style=\"font-family: 宋体; font-size: 10.5pt;\" times=\"\" new=\"\"><span style=\"font-family: 宋体;\">下载网址：</span></span><a href=\"http://manual-cn.hapseemate.cn/upg/pc/HapSeeMate_v2.1.exe\">http://manual-cn.hapseemate.cn/upg/pc/HapSeeMate_v2.1.exe</a><a href=\"http://www.hapseemate.cn/upg/pc/HapSeeMate_v1.6.exe\"><span style=\"color: rgb(0, 0, 255); font-family: 宋体;\"></span></a></span></span></span></p>\n" +
                            "<p style=\"margin: 0pt; text-align: left;\"><span style=\"font-family: 宋体; font-size: 10.5pt;\" times=\"\" new=\"\"><span style=\"font-family: 宋体;\">下载方式：在浏览器输入下载网址，点击确定，下载软件安装包</span></span></p>\n" +
                            "<p style=\"margin: 0pt; text-align: left;\"><span style=\"font-family: 宋体; font-size: 10.5pt;\" times=\"\" new=\"\"><span style=\"font-family: 宋体;\">下载截图</span></span></p>" +
                            "<p style=\"margin: 0pt; text-align: left;\"><span style=\"font-size: 24px;\"><span style=\"font-family: 宋体; font-size: 24px;\"><span style=\"font-family: 宋体; font-size: 16px;\"><img width=\"1137\" height=\"418\" title=\"5e95b15dd612a.png\" style=\"width: 100%; height: 297px;\" alt=\"5e95b15dd612a.png\" src=\""+require('@/assets/images/birds/cn/5e95b15dd612a.png')+"\"></span></span></span></p>" +
                            "<p style=\"margin: 0pt; text-align: left;\"><span style=\"font-size: 24px;\"><span style=\"font-family: 宋体; font-size: 24px;\"><span style=\"font-family: 宋体; font-size: 16px;\">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 图一</span></span></span></p>" +
                            "<p style=\"margin: 0pt; text-align: left;\"><span style=\"font-size: 24px;\"><span style=\"font-family: 宋体; font-size: 24px;\"><span style=\"font-family: 宋体; font-size: 16px;\"><img width=\"550\" height=\"234\" title=\"5d553fa73f28c.png\" style=\"width: 100%; height: 252px;\" alt=\"5d553fa73f28c.png\" src=\""+require('@/assets/images/birds/cn/5d553fa73f28c.png')+"\"></span></span></span></p>" +
                            "<p style=\"margin: 0pt; text-align: left;\"><span style=\"font-size: 24px;\"><span style=\"font-family: 宋体; font-size: 24px;\"><span style=\"font-family: 宋体; font-size: 16px;\">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 图二</span></span></span></p>" +
                            "<p style=\"margin: 0pt; text-align: left;\"><span style=\"font-size: 24px;\">二.安装</span></p>" +
                            "<p style=\"margin: 0pt; text-align: left;\"><span style=\"font-size: 18px;\"><span style=\"font-size: 16px;\"><img width=\"399\" height=\"286\" title=\"5d554014c4093.png\" style=\"width: 100%; height: 377px;\" alt=\"5d554014c4093.png\" src=\""+require('@/assets/images/birds/cn/5d554014c4093.png')+"\"></span></span></p>" +
                            "<p style=\"margin: 0pt; text-align: left;\"><span style=\"font-family: 宋体; font-size: 10.5pt;\" times=\"\" new=\"\"><span style=\"font-family: 宋体;\">安装过程中注意勾选</span>“创建桌面快捷方式”</span></p>" +
                            "<p style=\"margin: 0pt; text-align: left;\"><span style=\"font-family: arial, helvetica,sans-serif; font-size: 24px;\">三.添加设备</span></p>" +
                            "<p style=\"margin: 0pt;\"><span style=\"font-family: 宋体; font-size: 10.5pt;\" times=\"\" new=\"\"><span style=\"font-family: 宋体;\">点击摄像机的添加按键，看到如下添加页面</span></span></p>" +
                            "<p><span style=\"font-family: 宋体; font-size: 16px;\"><img style='width: 100%' title=\"5d5541163a25c.png\" alt=\"5d5541163a25c.png\" src=\""+require('@/assets/images/birds/cn/5d5541163a25c.png')+"\"></span></p>" +
                            "<p style=\"margin: 0pt; text-align: left;\"><span style=\"font-family: 宋体; font-size: 20px;\" times=\"\" new=\"\">局域网添加摄像机</span></p>" +
                            "<p><span style=\"font-family: 宋体; font-size: 14px;\"><span style=\"font-family: 宋体;\">摄像机连接的</span>wifi<span style=\"font-family: 宋体;\">和电脑</span><span style=\"font-family: Calibri;\">ip</span><span style=\"font-family: 宋体;\">处于同一个网段（连接同一个路由器）</span></span></p>" +
                            "<p style=\"text-align: left; color: rgb(0, 0, 0); font-family: Calibri; font-size: 14px; font-style: normal; font-weight: normal; margin-top: 0px; margin-bottom: 0px;\"><span style=\"font-family: 宋体; font-size: 14px;\"><span style=\"font-family: 宋体;\">1.点击</span></span><span style=\"font-family: 宋体; font-size: 14px;\"><span style=\"font-family: 宋体;\">刷新</span></span><span style=\"font-family: 宋体; font-size: 14px;\"><span style=\"font-family: 宋体;\">按键</span></span><span style=\"font-family: Calibri; font-size: 14px;\">&nbsp;</span></p>" +
                            "<p style=\"text-align: left; color: rgb(0, 0, 0); font-family: Calibri; font-size: 14px; font-style: normal; font-weight: normal; margin-top: 0px; margin-bottom: 0px;\"><span style=\"font-family: Calibri; font-size: 14px;\"><img style='width: 100%' title=\"5d55417fb1195.png\" alt=\"5d55417fb1195.png\" src=\""+require('@/assets/images/birds/cn/5d55417fb1195.png')+"\"></span></p>" +
                            "<p style=\"text-align: left; color: rgb(0, 0, 0); font-family: Calibri; font-size: 14px; font-style: normal; font-weight: normal; margin-top: 0px; margin-bottom: 0px;\"><span style=\"font-family: 宋体; font-size: 14px;\"><span style=\"font-family: 宋体;\">2.选择摄像机</span>ID<span style=\"font-family: 宋体;\">号，点击向右按键。</span></span></p>" +
                            "<p style=\"text-align: left; color: rgb(0, 0, 0); font-family: Calibri; font-size: 14px; font-style: normal; font-weight: normal; margin-top: 0px; margin-bottom: 0px;\"><span style=\"font-family: 宋体; font-size: 14px;\"><span style=\"font-family: 宋体;\"><img style='width: 100%' title=\"5d5541f675393.png\" alt=\"5d5541f675393.png\" src=\""+require('@/assets/images/birds/cn/5d5541f675393.png')+"\"></span></span></p>" +
                            "<p style=\"text-align: left; color: rgb(0, 0, 0); font-family: Calibri; font-size: 14px; font-style: normal; font-weight: normal; margin-top: 0px; margin-bottom: 0px;\"><span style=\"font-family: 宋体; font-size: 14px;\"><span style=\"font-family: 宋体;\">3.输入设备名称和</span></span><span style=\"font-family: 宋体; font-size: 14px;\"><span style=\"font-family: 宋体;\">输入配网时候设置的摄像机密码</span></span><span style=\"font-family: 宋体; font-size: 14px;\"><span style=\"font-family: 宋体;\">，点击确定。</span></span></p>" +
                            "<p style=\"text-align: left; color: rgb(0, 0, 0); font-family: Calibri; font-size: 14px; font-style: normal; font-weight: normal; margin-top: 0px; margin-bottom: 0px;\"><span style=\"font-family: 宋体; font-size: 14px;\"><span style=\"font-family: 宋体;\"><img style='width: 100%' title=\"5d5542ad42984.png\" alt=\"5d5542ad42984.png\" src=\""+require('@/assets/images/birds/cn/5d5542ad42984.png')+"\"></span></span></p>" +
                            "<p style=\"text-align: left; color: rgb(0, 0, 0); font-family: Calibri; font-size: 14px; font-style: normal; font-weight: normal; margin-top: 0px; margin-bottom: 0px;\"><span style=\"font-family: 宋体; font-size: 14px;\"><span style=\"font-family: 宋体;\"><img style='width: 100%' title=\"5d5542d21385b.png\" alt=\"5d5542d21385b.png\" src=\""+require('@/assets/images/birds/cn/5d5542d21385b.png')+"\"></span></span></p>" +
                            "<p style=\"text-align: left; color: rgb(0, 0, 0); font-family: Calibri; font-size: 14px; font-style: normal; font-weight: normal; margin-top: 0px; margin-bottom: 0px;\"><span style=\"font-family: 宋体; font-size: 10.5pt;\" times=\"\" new=\"\"><span style=\"font-family: 宋体;\">4.点击退出按键，双击设备列表出现的设备，摄像机添加成功。</span></span></p>" +
                            "<p style=\"text-align: left; color: rgb(0, 0, 0); font-family: Calibri; font-size: 14px; font-style: normal; font-weight: normal; margin-top: 0px; margin-bottom: 0px;\"><span style=\"font-family: 宋体; font-size: 10.5pt;\" times=\"\" new=\"\"><span style=\"font-family: 宋体;\"><img style='width: 100%' title=\"5d55430e04b2e.png\" alt=\"5d55430e04b2e.png\" src=\""+require('@/assets/images/birds/cn/5d55430e04b2e.png')+"\"></span></span></p>" +
                            "<p style=\"text-align: left; color: rgb(0, 0, 0); font-family: Calibri; font-size: 14px; font-style: normal; font-weight: normal; margin-top: 0px; margin-bottom: 0px;\"><span style=\"font-family: 宋体; font-size: 10.5pt;\" times=\"\" new=\"\"><span style=\"font-family: 宋体;\"><img style='width: 100%' title=\"5d55433b0f6b7.png\" alt=\"5d55433b0f6b7.png\" src=\""+require('@/assets/images/birds/cn/5d55433b0f6b7.png')+"\"></span></span></p>" +
                            "<p style=\"text-align: left; color: rgb(0, 0, 0); font-family: Calibri; font-size: 14px; font-style: normal; font-weight: normal; margin-top: 0px; margin-bottom: 0px;\"><span style=\"font-family: 宋体; font-size: 10.5pt;\" times=\"\" new=\"\"><span style=\"font-family: 宋体;\"><br></span></span></p><p style=\"margin: 0pt;\"><span style=\"font-family: 宋体; font-size: 20px;\" times=\"\" new=\"\">非局域网添加</span></p>\n" +
                            "<p style=\"margin: 0pt;\"><span style=\"font-family: 宋体; font-size: 10.5pt;\" times=\"\" new=\"\"><span style=\"font-family: 宋体;\">摄像机和电脑不在一个网络环境下</span></span></p>\n" +
                            "<p style=\"margin: 0pt;\"><span style=\"font-family: 宋体; font-size: 14px;\"><span style=\"font-family: 宋体;\">1.点击添加按键，打开</span>“手动添加设备”</span></p>\n" +
                            "<p style=\"margin: 0pt;\"><span style=\"font-family: 宋体; font-size: 14px;\"><img style='width: 100%' title=\"5d5543cd94d96.png\" alt=\"5d5543cd94d96.png\" src=\""+require('@/assets/images/birds/cn/5d5543cd94d96.png')+"\"></span></p>\n" +
                            "<p style=\"margin: 0pt;\"><span style=\"font-family: 宋体; font-size: 14px;\"><span style=\"font-family: 宋体;\">2.自己手动设置一个设备名称，输入设备的</span>ID<span style=\"font-family: 宋体;\">号和密码，点击确定按键，之后点击退出按键。</span></span></p>\n" +
                            "<p style=\"margin: 0pt;\"><span style=\"font-family: 宋体; font-size: 14px;\"><img style='width: 100%' title=\"5d55440033936.png\" alt=\"5d55440033936.png\" src=\""+require('@/assets/images/birds/cn/5d55440033936.png')+"\"></span></p>\n" +
                            "<p style=\"margin: 0pt;\"><span style=\"font-family: 宋体; font-size: 14px;\"><span style=\"font-family: 宋体;\">3.双击设备列表出现的设备，摄像机添加成功。</span></span></p>\n" +
                            "<p style=\"margin: 0pt;\"><span style=\"font-family: 宋体; font-size: 10.5pt;\" times=\"\" new=\"\"><span style=\"font-family: 宋体;\"><img style='width: 100%' title=\"5d55443138222.png\" alt=\"5d55443138222.png\" src=\""+require('@/assets/images/birds/cn/5d55443138222.png')+"\"></span></span></p>\n"
                    },
                ]
            },
            {
                title: "故障排除",
                active: require("@/assets/images/hapsee/cn/page02_hover.png"),
                normal: require("@/assets/images/hapsee/cn/page02.png"),
                data:[
                    {
                        text: "摄像机在使用过程中出现掉线，卡顿，反应慢怎么处理？",
                        url: "/content/index",
                        content: "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.<span style=\"font-family: 宋体,SimSun; font-size: 18px;\">更新APP和摄像机固件到最新版本</span></span></p>\n" +
                            "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.检查摄像机连接的<span style=\"font-family: Calibri; font-size: 16px;\">WiFi</span>或者当前网络环境是否通畅，摄像机与路由器的距离是否过远。</span></p>\n" +
                            "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">3.<span style=\"font-family: 宋体,SimSun; font-size: 18px;\">复位摄像机重新配置网络。</span></span></p>\n" +
                            "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\"><span style=\"font-family: 宋体;\">4.摄像机<span style=\"font-family: Calibri;\">WiFi</span>信号接收弱（设备故障），手机直连摄像机发出的热点，观看监控是否有卡顿的现象，如有该现象需更换摄像</span><span style=\"font-family: 宋体;\">机。</span></span></p>"
                    },
                    {
                        text: "摄像机在局域网之内手机可以观看，外网观看不了？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.客户连接的是摄像机的热点，用4G网络或者其他WiFi就掉线了。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.客户是用是光猫设备，没有打开数据传输端口，可以让相应的运营商帮客户设置好光猫。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">3.修改路由器设置，把路由器的MTU（最大传输单元）值该为默认设置，然后重启路由器。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\"><span style=\"font-family: 宋体;\">4.设备和路由器断电重启，退出</span>APP<span style=\"font-family: 宋体;\">之后重新进入</span><span style=\"font-family: Calibri;\">APP</span><span style=\"font-family: 宋体;\">，确认设备是否可以正常监控。</span></span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">5.更新APP和摄像机固件到最新版本。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">6.复位设备重新添加</span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "APP打开对讲，摄像机这边没有发出声音?",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">1.苹果手机在 手机设置-开心看mate-麦克风 开关要打开。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.安卓在</span> <span style=\"font-family: 宋体; font-size: 18px;\">手机设置</span>-<span style=\"font-family: 宋体; font-size: 18px;\">应用</span><span style=\"font-family: Calibri; font-size: 18px;\">-</span><span style=\"font-family: 宋体; font-size: 18px;\">权限管理</span><span style=\"font-family: Calibri; font-size: 18px;\">-</span><span style=\"font-family: 宋体; font-size: 18px;\">开心看</span><span style=\"font-family: Calibri; font-size: 18px;\">mate-</span><span style=\"font-family: 宋体; font-size: 18px;\">麦克风</span>&nbsp;<span style=\"font-family: 宋体; font-size: 18px;\">要打开。</span></span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">3.设备喇叭坏了，开机有没有提示语言可以判断。</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">4.<span style=\"font-family: 宋体; font-size: 18px;\">有该问题更新摄像机固件和</span>APP<span style=\"font-family: 宋体; font-size: 18px;\">版本</span></span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "夜间监控画面一片漆黑或者白天监控画面发红?",
                        url: "/content/index",
                        content: "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">夜间监控画面发黑，先检查摄像机这边红外灯是否打开，其次在红外灯开启的情况下给设备重新上电看是否切换到正常画面；白天监控画面发红，重启设备看是否切换过来，以及重启存在问题请联系卖家处理。</span></p>"
                    },
                    {
                        text: "APP打开监听，手机接收不到摄像机发出的声音?",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.手机声音没有打开，或者处于静音状态</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.摄像机咪头损坏</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\"><span style=\"font-family: 宋体;\">3.有该问题更新摄像机固件和</span>APP<span style=\"font-family: 宋体;\">版本</span></span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "相册里面的视频没有声音是什么原因？",
                        url: "/content/index",
                        content: "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">在设备监控页面进行屏幕录制的时候没有打开监听的功能</span></p>"
                    },
                    {
                        text: "摄像机SD卡录像没有录像文件？",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.SD卡没有读取出来，断电重新插拔内存卡</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.SD卡损坏，更换新的内存卡</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">3.全时录像没有打开</span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\"><span style=\"font-family: 宋体; font-size: 18px;\">4.观察监控时间是否为当前时间，如果时间不正确要在设置里面打开“时钟”按键，点击对号，即可同步当前监控时间，之后重新设置录像。（客户热点模式录像需要进行该设置）</span></span></p></li>\n" +
                            "    <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: 宋体; font-size: 16px; font-style: normal; font-weight: normal; margin-top: 1em; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">5.更新摄像机固件和APP版本</span></p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "APP上面无法读取到内存卡的容量",
                        url: "/content/index",
                        content: "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.内存卡损毁掉。</span></p>\n" +
                            "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-size: 18px;\"><span style=\"font-family: 宋体;\">2.<span style=\"font-family: 宋体;\">内存卡格式不正确，需要到电脑上面使用</span></span><span style=\"color: rgb(51, 51, 51); letter-spacing: 0px; font-family: Helvetica; font-style: normal;\">FAT32</span><span style=\"color: rgb(51, 51, 51); letter-spacing: 0px; font-family: 宋体; font-style: normal;\">格式化内存卡。</span></span></p>\n" +
                            "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"color: rgb(51, 51, 51); letter-spacing: 0px; font-family: 宋体; font-size: 18px; font-style: normal;\">3.重新插拔内存卡。</span></p>"
                    },
                    {
                        text: "摄像机支持什么类型的内存卡",
                        url: "/content/index",
                        content: "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">速度：Class4及以上的都支持</span></p>\n" +
                            "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\"><span style=\"font-family: 宋体; font-size: 18px;\">容量</span>:<span style=\"font-family: 宋体; font-size: 18px;\">支持128GSD卡</span></span></p>\n" +
                            "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">类型：TF卡</span></p>\n" +
                            "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体,SimSun; font-size: 18px;\">分区格式：FAT32，<span style=\"font-family: Calibri; font-size: 16px;\">exFAT</span></span></p>"
                    },
                ]
            },
            {
                title: "功能使用",
                active: require("@/assets/images/hapsee/cn/page03_hover.png"),
                normal: require("@/assets/images/hapsee/cn/page03.png"),
                data: [
                    {
                        text: "如何复位摄像机",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "    <li><p>1.在摄像机底部找到复位键或者复位孔，旁边会有 RESET 的英文字母。</p></li>\n" +
                            "    <li><p>2.长按复位键三秒或者用取卡针戳小孔三秒（取卡针在配件的螺丝包里面有哦），等待摄像机发出“复位成功”的语音提示即可完成复位步骤。</p></li>\n" +
                            "</ol>"
                    },
                    {
                        text: "手机上面无法接收到报警推送消息",
                        url: "/content/index",
                        content: "<p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><strong><span style=\"font-family: 宋体; font-size: 24px;\"><span style=\"font-size: 20px;\"></span>苹果手机<span style=\"font-size: 24px;\"></span></span></strong></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">打开设置---开心看<span style=\"font-family: Calibri;\">Mate---</span>通知 &nbsp;设置以下方式</span></p>\n" +
                            "        <p><img width='100%' title=\"5d312178c7d3c.png\" alt=\"5d312178c7d3c.png\" src=\""+require('@/assets/images/hapsee/cn/5d312178c7d3c.png')+"\"></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><strong><span style=\"font-family: 宋体; font-size: 24px;\">安卓手机<span style=\"font-size: 20px;\"></span></span></strong></p><p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 20px;\">Vivo和<span style=\"font-family: Calibri;\">OPPO</span>手机的设置</span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">VIVO<span style=\"font-family: 宋体;\">：</span></span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">1.<span style=\"font-family: 宋体; font-size: 16px;\">开启</span>APP<span style=\"font-family: 宋体; font-size: 16px;\">添加设备，在设置界面打开推送开关；</span></span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">2.<span style=\"font-family: 宋体; font-size: 16px;\">开启移动侦测并触发；</span></span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">3.<span style=\"font-family: 宋体; font-size: 16px;\">左键上划，弹出设置菜单，下拉</span>Mate<span style=\"font-family: 宋体; font-size: 16px;\">图标，点击锁，就设置为白名单；</span></span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">4.<span style=\"font-family: 宋体; font-size: 16px;\">返回</span>APP<span style=\"font-family: 宋体; font-size: 16px;\">，点击左键，弹出退出提示框，点击确定。</span></span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\"><span style=\"font-family: 宋体; font-size: 16px;\">设备可在关闭</span>APP<span style=\"font-family: 宋体; font-size: 16px;\">的情况下，接收推送；</span></span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">OPPO<span style=\"font-family: 宋体; font-size: 16px;\">：</span></span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">1.<span style=\"font-family: 宋体; font-size: 16px;\">开启</span>APP<span style=\"font-family: 宋体; font-size: 16px;\">添加设备，在设置界面打开推送开关；</span></span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">2.<span style=\"font-family: 宋体; font-size: 16px;\">开启移动侦测并触发；</span></span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">3.<span style=\"font-family: 宋体; font-size: 16px;\">进入设置</span>—&gt;<span style=\"font-family: 宋体; font-size: 16px;\">用用管理</span>—&gt;Mate—&gt;<span style=\"font-family: 宋体; font-size: 16px;\">通知管理：开启锁屏通知；</span></span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 16px;\">4.<span style=\"font-family: 宋体; font-size: 16px;\">进入设置</span>—<span style=\"font-family: 宋体; font-size: 16px;\">应用管理</span>—&gt;Mate—&gt;<span style=\"font-family: 宋体; font-size: 16px;\">耗电保护：允许后台运行；</span></span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 18px;\">5.<span style=\"font-family: 宋体;\">允许自动启动；</span></span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 18px;\">6.<span style=\"font-family: 宋体; font-size: 18px;\">设置</span>—&gt;<span style=\"font-family: 宋体; font-size: 18px;\">电池</span>—&gt;<span style=\"font-family: 宋体; font-size: 18px;\">应用冻结（速冻）</span>—&gt;Mate<span style=\"font-family: 宋体; font-size: 18px;\">：关闭冻结；</span></span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 18px;\">7.<span style=\"font-family: 宋体; font-size: 18px;\">返回</span>APP<span style=\"font-family: 宋体; font-size: 18px;\">，邮件上划，弹出退出提示框，点击确定；</span></span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 20px;\">其他安卓手机接收不到推送时：</span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 18px;\">1.<span style=\"font-family: 宋体;\">首先进入设置页面，将推送关闭再打开一次，检查是否可接收推送；</span></span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 18px;\">2.<span style=\"font-family: 宋体; font-size: 18px;\">如果不能，退出</span>APP<span style=\"font-family: 宋体; font-size: 18px;\">，再次开启</span>APP<span style=\"font-family: 宋体; font-size: 18px;\">，检查是否会弹出推送警告提示框，如果有，点击设置，进入允许推送，之后再次确认是否可推送；</span></span></p>\n" +
                            "        <p style=\"margin: 1em 0px 0px; text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: Calibri; font-size: 18px;\"><span style=\"font-family: 宋体; font-size: 18px;\">3.如果可以之后，请将</span>Mate<span style=\"font-family: 宋体; font-size: 18px;\">设置为白名单；（见上面</span>VIVO<span style=\"font-family: 宋体; font-size: 18px;\">白名单设置，</span>OPPO<span style=\"font-family: 宋体; font-size: 18px;\">允许后台运行和关闭冻结）</span></span></p>\n"
                    },
                    {
                        text: "APP客户端支持那些手机系统？",
                        url: "/content/index",
                        content: "<p style=\"margin: 0pt;\"><span style=\"font-family: 宋体; font-size: 18px;\">支持Android4.0以上版本已及<span style=\"font-family: Calibri;\" times=\"\" new=\"\">iOS7.0</span>以上版本手机系统。</span></p>"
                    },
                    {
                        text: "本地录像的分享",
                        url: "/content/index",
                        content: "<p style=\"text-align: justify; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">打开相册打开相片或者录像视频点击分享按键，可以分享到其他的手机APP软件（微信，QQ）。</span></p>"
                    },
                    {
                        text: "如何删除录像",
                        url: "/content/index",
                        content: "<ol class=\" list-paddingleft-2\" style=\"list-style-type: none;\">\n" +
                            "            <li><p style=\"text-align: justify; color: rgb(0, 0, 0); font-family: Calibri; font-size: 14px; font-style: normal; font-weight: normal; margin-top: 0px; margin-bottom: 0px; -ms-text-justify: inter-ideograph;\"><span style=\"font-family: 宋体; font-size: 18px;\">1.SD卡录像：全部删除是直接在设置页面选择<span style=\"font-family: Calibri;\">SD</span>卡格式化；部分删除，把内存卡放到电脑上面，在摄像机<span style=\"font-family: Calibri;\">ID</span>号目录下选择需要删除的文件。</span></p></li>\n" +
                            "            <li><p style=\"color: rgb(0, 0, 0); font-family: Calibri; font-size: 14px; font-style: normal; font-weight: normal; margin-top: 0px; margin-bottom: 0px;\"><span style=\"font-family: 宋体; font-size: 18px;\">2.本地录像：在相册里面选择摄像机目录下不需要的照片和视频选择删除掉。</span></p></li>\n" +
                            "        </ol>"
                    },
                ]
            },
            {
                title: "云服务",
                active: require("@/assets/images/hapsee/cn/page04_hover.png"),
                normal: require("@/assets/images/hapsee/cn/page04.png"),
                data: [
                    {
                        text: "如何分享设备给家人？",
                        url: "/content/index",
                        content: "<p>进入设备设置页面，找到【分享设备】，进入分享管理页面。</p>\n" +
                            "        <p>&nbsp;<img width='100%' title=\"5e634ec337d3b.png\" alt=\"5e634ec337d3b.png\" src=\""+require("@/assets/images/hapsee/cn/5e634ec337d3b.png")+"\"></p>\n" +
                            "        <p>用户可通过面对面扫码分享和输入账号分享两种方式将当前设备分享至其他账号.同一台设备最多可分享给5个分享账号，分享账号权限默认为：观看和回放录像，没有有设置权限。</p>\n" +
                            "        <p>&nbsp;<img width='100%' title=\"5e63551ee59b1.png\" alt=\"5e63551ee59b1.png\" src=\""+require("@/assets/images/hapsee/cn/5e63551ee59b1.png")+"\"></p>\n" +
                            "        <p>方法①：选择【面对面扫描分享】，APP生成设备分享二维码</p>\n" +
                            "        <p><img width='100%' title=\"5e6355845335a.png\" alt=\"5e6355845335a.png\" src=\""+require("@/assets/images/hapsee/cn/5e6355845335a.png")+"\"></p>\n" +
                            "        <p>使用另一部手机，登录其他账号后，点击【添加】—&gt;【分享添加摄像机】，并扫描上方的设备的分享二维码，即可添加到分享账号。<span style=\"color: rgb(255, 0, 0);\">说明：分享二维码有效期为10分钟，请在有效期内添加。</span></p>\n" +
                            "        <p><img width='100%' title=\"5e6355d3ef1b5.png\" alt=\"5e6355d3ef1b5.png\" src=\""+require("@/assets/images/hapsee/cn/5e6355d3ef1b5.png")+"\"></p>\n" +
                            "        <p><span style=\"font-family: 宋体;\"><br></span></p>\n" +
                            "        <p>方法②：选择【输入账号分享】，并输入要分享的账号，选择分享账号权限后，点击确定，就可以将设备分享至分享账号。</p>\n" +
                            "        <p><span style=\"font-family: 宋体;\"></span></p><p><img width='100%' title=\"5e63560eaaaed.png\" alt=\"5e63560eaaaed.png\" src=\""+require("@/assets/images/hapsee/cn/5e63560eaaaed.png")+"\"></p>"
                    },
                    {
                        text: "设备权限变了是为什么？怎样才能恢复设备的主账号权限？",
                        url: "/content/index",
                        content: "<p>注册并登录后，之前的摄像机会自动绑定到您的账号。如果有设备已经被其他账号绑定，则会显示为分享设备，分享设备缩略图右上角有[分享]字样。</p>\n" +
                            "        <p><span style=\"color: rgb(255, 0, 0);\">特别注意：分享设备可以观看和回放，但没有设置权限，请您使用主账号进行设置。</span></p>\n" +
                            "        <p><img width='100%' title=\"5e634e3a1147c.png\" alt=\"5e634e3a1147c.png\" src=\""+require("@/assets/images/hapsee/cn/5e634e3a1147c.png")+"\"></p>"
                    },
                    {
                        text: "忘记账号密码怎么办？",
                        url: "/content/index",
                        content: "<p><span style=\"font-family: 等线; font-size: 14px;\">&nbsp;开启APP，在登录页面右下角找到【忘记密码】，进入密码找回页面：</span></p>\n" +
                            "        <p><img width='100%' title=\"5e634d606b389.png\" alt=\"5e634d606b389.png\" src=\""+require("@/assets/images/hapsee/cn/5e634d606b389.png")+"\"></p>\n" +
                            "        <p><img width='100%' title=\"5e634d82a473b.png\" alt=\"5e634d82a473b.png\" src=\""+require("@/assets/images/hapsee/cn/5e634d82a473b.png")+"\"></p>\n" +
                            "        <p>输入要找回密码的手机/邮箱账号，并获取验证码后，点击下一步，之后就可设置新的账号密码。如下图。</p>\n" +
                            "        <p><img width='100%' title=\"5e634dbe12c04.png\" alt=\"5e634dbe12c04.png\" src=\""+require("@/assets/images/hapsee/cn/5e634dbe12c04.png")+"\"></p>"
                    },
                    {
                        text: "同一时间仅支持几部手机登录？",
                        url: "/content/index",
                        content: "<p>同一个账号在同一时间仅支持一部手机登录，暂不支持多设备同时登录。如：在手机A已登录账号C的情况下，手机B登录账号C，那么手机A在下拉刷新设备列表或重新开启APP时会被强制注销登录。</p>\n" +
                            "         <p>账号内的网络摄像机，请使用【分享设备】功能分享给家人。</p>"
                    },
                    {
                        text: "更新APP后，如何注册账号",
                        url: "/content/index",
                        content: "<p>1.在升级并开启APP后，会看到欢迎页面和登录界面，请根据注册引导点击登录界面左下角的【立即注册】，使用有效手机号或邮箱进行注册。</p>\n" +
                            "        <p><img width='100%' title=\"5e6342c1dc456.png\" alt=\"5e6342c1dc456.png\" src=\""+require("@/assets/images/hapsee/cn/5e6342c1dc456.png")+"\"></p>\n" +
                            "        <p><span style=\"font-family: 等线;\">2. App注册页面如下图所示：请按照页面要求进行注册，具体步骤如下：</span></p>\n" +
                            "        <p><span style=\"font-family: 等线;\"><span style=\"font-family: 等线; font-size: 14px;\">手机注册用户请选择国家或地区[如果是中国大陆地区可以忽略这一步]—&gt;输入有效手机号/邮箱—&gt;设置账号密码—&gt;点击【获取验证码】—&gt;输入有效验证码—&gt;勾选【请阅读并统一《用户协议》】—&gt;点击注册。</span></span></p>\n" +
                            "        <p><span style=\"font-family: 等线;\"><img width='100%' title=\"5e63438e512df.png\" alt=\"5e63438e512df.png\" src=\""+require("@/assets/images/hapsee/cn/5e63438e512df.png")+"\"></span></p>"
                    },
                ]
            },
        ],
        // 操作视频
        tabRight: [
            {text: "AP热点添加摄像机", playback: "http://player.youku.com/embed/XNDQ0ODc2MTY0NA=="},
            {text: "网线添加摄像机", playback: "http://player.youku.com/embed/XNDQ0ODY2MDMxMg=="},
            {text: "声波添加摄像机", playback: "http://player.youku.com/embed/XNDQ0ODY2MjQ2MA=="},
            {text: "无网观看摄像机", playback: "http://player.youku.com/embed/XNDQ0ODY2NTM0NA=="}
        ],
        footer: "Copyright © 2016 - 2023 HapSeeMate All Rights Reserved."
    },
}
